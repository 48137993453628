import MicroModal from 'micromodal';

export default () => {
    /**
     * Save a modal instance while the Modal is open
     * Save a videoType instance while the Modal is open
     */
    let __modal = '';
    let __videoType = '';

    MicroModal.init({
        onShow: (modal) => {
            if (!isCurrentModule(modal)) {
                return;
            }
            openActions(modal);
        }, // [1]
        onClose: (modal) => {
            if (!isCurrentModule(modal)) {
                return;
            }
            closeActions(modal);
        },
        disableScroll: true, // [6]
        awaitOpenAnimation: false, // [8]
        awaitCloseAnimation: true, // [9]
    });

    /**
     * Check if current module is triggered
     * @param {object} modal
     * @return boolean
     * */
    function isCurrentModule(modal) {
        return !!modal.closest('[data-module=modalVideoA1]');
    }

    /**
     * Provide actions when Modal is open
     * @param {object} modal
     * @return void
     * */
    function openActions(modal) {
        __modal = modal;
        __videoType = getVideoType(modal);

        document.body.classList.add('modal-open');

        /*if ('embed' === __videoType) {
            VimeoVideo.playVideo();
        }*/
        if ('file' === __videoType) {
            FileVideo.playVideo();
        }
    }

    /**
     * Provide actions when Modal is closed
     * @param {object} modal
     * @return void
     * */
    function closeActions(modal) {
        document.body.classList.remove('modal-open');

        /*if ('embed' === __videoType) {
            VimeoVideo.stopVideo();
        }*/
        if ('file' === __videoType) {
            FileVideo.stopVideo();
        }

        __modal = '';
        __videoType = '';
    }

    /**
     * Get type of the video according to 01-atom video [data-video-type]
     * @param {object} modal
     * @return string|boolean
     * */
    function getVideoType(modal) {
        const video = modal.querySelector('.a-video');
        if (null === video) {
            return false;
        }
        if (video.hasAttribute('data-video-type')) {
            return video.getAttribute('data-video-type');
        }
        return false;
    }

    /**
     * Functions for Vimeo Video
     * @param {object, null} iframe
     * @param {object, null} player
     * @param {boolean} libraryLoaded
     * */
    let VimeoVideo = {
        /** Save the instances while the Modal is open */
        iframe: null,
        player: null,
        /** Flag - Vimeo API is Loaded */
        libraryLoaded: false,

        onReadyCallback: function () {
            VimeoVideo.libraryLoaded = true;
            VimeoVideo.playVideo();
        },
        loadAPI: function () {
            let script = document.createElement('script');
            script.src = 'https://player.vimeo.com/api/player.js';
            script.onload = this.onReadyCallback;
            document.head.append(script);
        },
        playVideo: function () {
            this.iframe = __modal.querySelector('iframe');

            /** if no <iframe> or <iframe> is not Vimeo */
            if (!this.iframe || -1 === this.iframe.src.indexOf('vimeo.com')) {
                return;
            }

            /** Load Vimeo API */
            if (!this.libraryLoaded) {
                this.loadAPI();
                return;
            }

            /** Play video */
            // eslint-disable-next-line no-undef
            this.player = new Vimeo.Player(this.iframe);
            this.player.setCurrentTime(0.01);
            this.player.play();
        },
        stopVideo: function () {
            if (!this.iframe || -1 === this.iframe.src.indexOf('vimeo.com')) {
                return;
            }
            this.player.pause();

            this.iframe = null;
            this.player = null;
        },
    };

    /**
     * Functions for File Video
     * @param {object, null} video
     * */
    let FileVideo = {
        /** Save the instances while the Modal is open */
        video: null,

        playVideo: function () {
            this.video = __modal.querySelector('video');

            if (!this.ableToPlay()) {
                return;
            }

            this.video.play();
        },
        stopVideo: function () {
            if (!this.video) {
                return;
            }

            this.video.pause();
            this.video.currentTime = 0;

            this.video = null;
        },
        ableToPlay: function () {
            if (!this.video) {
                return false;
            }
            if (!this.video.hasAttribute('autoplay')) {
                return false;
            }
            return true;
        },
    };
};
