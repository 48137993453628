function showAccordion() {
    const classes = {
        block: 'm-accordion',
        group: 'm-accordion__group',
        link: 'm-accordion__heading-link',
        text: 'm-accordion__text-wrapper',
        verticalControl: 'm-accordion__control--vertical',
        active: 'is-active',
    };

    $('.m-accordion__heading-wrapper').click(function (e) {
        const group = $(e.target).closest(`.${classes.group}`);
        const text = group.find(`.${classes.text}`);
        const controlVertical = group.find(`.${classes.verticalControl}`);

        group.toggleClass(classes.active);
        controlVertical.toggleClass('rotate-90');
        // eslint-disable-next-line jquery/no-slide
        text.slideToggle(300);
    });

    /**
     * Disable scroll page to #hash on click
     * Remove Hash from URL when close Accordion
     **/
    $(`.${classes.link}`).click(function (e) {
        const group = $(e.target).closest(`.${classes.group}`);
        e.preventDefault();

        let newURL = '';
        if (group.hasClass(classes.active)) {
            const pageHashTarget = window.location.hash;
            newURL = window.location.href.replace(`${pageHashTarget}`, '');
        } else {
            newURL = e.currentTarget.href;
        }
        window.history.pushState({ path: newURL }, '', newURL);
    });

    /**
     * Open accordion if url-hash exists
     **/
    (function () {
        const anchor = location.hash
            ? document.querySelector(location.hash)
            : '';
        if (!anchor) {
            return;
        }
        if (!anchor.closest('[data-module="showAccordion"]')) {
            return;
        }

        const group = $(anchor).closest(`.${classes.group}`);
        const link = group.find(`.${classes.link}`);
        setTimeout(function () {
            link.click();
        }, 700);
    })();
}

export default showAccordion;
