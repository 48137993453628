/* eslint-disable */
import $ from 'jquery';
window.$ = window.jQuery = $;
import '../../../../global/js/vendors/fullpage/scrolloverflow.min.js';
import '../../../../global/js/vendors/fullpage/fullpage.scrollOverflowReset.min.js';
import '../../../../global/js/vendors/fullpage/fullpage.extensions.min';
import '../../../../global/js/vendors/fullpage/fullpage.scrollHorizontally.min';
import '../../../../global/js/vendors/fullpage/fullpage.parallax.min.js';
import fullpage from '../../../../global/js/vendors/fullpage/fullpage';

function impactReport() {
    jQuery(document).ready(function() {
        new fullpage('#fullpage', {
            easing: 'easeInOutCubic',
            easingcss3: 'ease',
            licenseKey: 'ED60277E-CDBC48B4-9BC6F3CA-96D3A40B',
            sectionSelector: '.o-base-slider-holder__slide',
            navigation: true,
            navigationPosition: 'right',
            controlArrows: true,
            scrollHorizontally: true,
            slidesNavigation: true,
            scrollingSpeed: 800,
            scrollOverflowResetKey: 'C3225272-509D4BA6-82B8DC4C-8FBC2D0B',
            scrollOverflowReset: true,
            scrollOverflow: true,
        });
    });
}

export default impactReport;
